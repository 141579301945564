'use strict';
require('./object.contacts.component.scss');

//objectContacts
export default class ObjectContactsComponent {
  constructor() {
    this.restrict = 'A'
    this.template = require('./object.contacts.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectContactsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectContactsComponentController {
  constructor() {
    this.types = [
        'RESIDENT',
        'BMA',
        'FIRE_PREVENTION_OFFICER',
        'CURATOR',
        'OWNER',
        'GAS',
        'CEO',
        'CHIMNEY_SWEEPER',
        'CONTACT_PERSON',
        'JANITOR',
        'GATE',
        'STORE_MANAGEMENT',
        'REGULATORY_AGENCY',
        'MANAGEMENT',
        'SECURITY',
        'SECURITY_OFFICER',
        'INCIDENT_OFFICER',
        'RADIATION_PROTECTION_OFFICER',
        'ELECTRICITY',
        'MISC',
        'WATER',
        'PLANT_FIRE',
        'PLANT_SECURITY'
    ];
  }

  add() {
    var contact = {
      name: '',
      type: 'RESIDENT',
      contact: '',
        company: '',
        note: '',
        email: ''
      };
    if (angular.isUndefined(this.alarmObject.contacts)) {
      this.alarmObject.contacts = [];
    }
    this.alarmObject.contacts.push(contact);
  };

  delete(contact) {
    this.alarmObject.contacts.splice(this.alarmObject.contacts.indexOf(contact), 1);
  };

}
