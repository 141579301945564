import RestService from "../../../../../services/rest.service";
import { IScope } from "angular";
import { EVehiclePositionProcessingState, VehicleGeoTrackingStateResponse } from "../../../../../data/geotracking.data";
require("./admin.tracking.settings.scss")

export default class AdminTrackingSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.tracking.settings.html');
    this.scope = {
    };
    this.controller = AdminTrackingSettingsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
/*@ngInject*/
class AdminTrackingSettingsController {
  private isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;
  private trackingStateResponse: VehicleGeoTrackingStateResponse;


  constructor($scope: IScope, restService: RestService) {
    this.$scope = $scope;
    this.restService = restService;
    this.loadTrackingState();
  }

  loadTrackingState() {
    this.isLoading = true;
    this.restService.getVehicleGeoTrackingState().then(result => {
      this.trackingStateResponse = result;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }
  stopStartTracking() {
    this.isLoading = true;
    var newState = {} as VehicleGeoTrackingStateResponse
    if (this.trackingStateResponse.vehiclePositionProcessingState === EVehiclePositionProcessingState.RUNNING) {
      newState.vehiclePositionProcessingState = EVehiclePositionProcessingState.STOPPED;
    } else if (this.trackingStateResponse.vehiclePositionProcessingState === EVehiclePositionProcessingState.STOPPED) {
      newState.vehiclePositionProcessingState = EVehiclePositionProcessingState.RUNNING;
    } else {
      newState.vehiclePositionProcessingState = EVehiclePositionProcessingState.RUNNING;
    }
    this.restService.stopStartTracking(newState).then(changedState => {
      this.trackingStateResponse = changedState;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

}