'use strict';

import {ILogService, IRootScopeService, IScope, IWindowService} from "angular";
import {AlarmMonitor, AlarmMonitorAddressbookResponse} from "../../../../data/am4.data";
import RestService from "../../../../services/rest.service";
import {SortParams} from "../../../views/addressbook.view.component/addressbook.view.component";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./alarmmonitor.component.css');

export default class AlarmmonitorComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        addressbook: '='
      }
    this.template = require('./alarmmonitor.component.html')

    this.controller = AlarmMonitorController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//tableAlarmmonitor
/* @ngInject */
class AlarmMonitorController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public $window: IWindowService;
  public $http: any;
  public $uibModal: any;
  public restService: RestService;
  public listeners = [];
  public isLoading = true;
  public data: AlarmMonitorAddressbookResponse;
  public priv: PrivilegeService;
  public hasAM4Create:boolean= false;
  public hasAM4Delete:boolean= false;
  public hasAM4: boolean = false;
  public isTriggeringRefresh = false;

  public params: SortParams = {
    currentPage: 0,
    pageSize: 20
  } as SortParams;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $window: IWindowService, $http, $uibModal, restService: RestService, privilegeService:PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$window = $window;
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.restService = restService;
    this.priv = privilegeService;

    this.initListeners();
  }

  /**
 * Triggers an reload
 */
  reload() {
    this.params.currentPage = 0;
    this.pageChanged();
  }

  /**
   * Loads the entries paginated
   * @returns
   */
  pageChanged() {
    if (!this.hasAM4){
      return Promise.reject("Missing Permission for AlarmMonitor");
    }

    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadAlarmMonitors(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.pageSize, undefined)
        .then((result: AlarmMonitorAddressbookResponse) => {
          this.data = result;
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }


  /**
   *Add a new AM instance to the addressbook
   */
  addAlarmMonitor() {
    var modalInstance = this.$uibModal.open({
      template: require('../../../modals/addressbook/edit.AM4.modal/edit.AM4.modal.html'),
      controller: 'EditAm4ModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        am: () => {
          return undefined;
        }
      }
    });

    modalInstance.result.then(() => {
      this.params.currentPage = 0;
      this.pageChanged();
    });
  };

  /*
    Select an AM to open the edit dialog
  */
  selectAlarmMonitor(alarmMonitor: AlarmMonitor) {
    var modalInstance = this.$uibModal.open({
      template: require('../../../modals/addressbook/edit.AM4.modal/edit.AM4.modal.html'),
      controller: 'EditAm4ModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        am: () => {
          return alarmMonitor;
        }
      }
    });

    modalInstance.result.then(() => {
      this.params.currentPage = 0;
      this.pageChanged();
    });
  };

  /**
   * Delete an existing AlarmMonitor
   * @param alarmMonitor
   */
  deleteAlarmMonitor(alarmMonitor: AlarmMonitor) {
    this.isLoading = true;
    this.restService.deleteAMById(alarmMonitor.id, () => {
      this.isLoading = false;
      this.params.currentPage = 0;
      this.pageChanged();
    }, (errorResponse) => {
      this.isLoading = false;
      this.$log.error(errorResponse);
    });
  };

  /**
   * Refresh the AMweb
   */
  triggerRefresh(alarmMonitor: AlarmMonitor) {
    this.isTriggeringRefresh = true;

    this.restService.refreshAMweb(alarmMonitor.id).finally(() => {
      this.isTriggeringRefresh = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Download AM4 Config for MQTT
   */
  downloadConfig(alarmMonitor: AlarmMonitor) {
    this.$window.open(this.restService.getBaseUrl() + '/monitors/' + encodeURIComponent(alarmMonitor.id) + '/config?Authorization=' + this.$http.defaults.headers.common.Authorization, '_blank');
  };


  initListeners() {
    this.listeners.push(this.$rootScope.$on('addressbook.view.tab', (event, source) => {
      if (source === 2) {
        this.hasAM4= this.priv.has(RolePrivilege.Addressbook_AM4);
        this.hasAM4Create = this.priv.has(RolePrivilege.Addressbook_AM4_Create);
        this.hasAM4Delete = this.priv.has(RolePrivilege.Addressbook_AM4_Delete)
        // Trigger reload
        this.pageChanged();
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}
