'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { UserAccount } from "../../../../data/account.data";
import { AlarmObject } from "../../../../data/objects.data";
import { RolePrivilege } from "../../../../data/privileges.enum";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./edit.alarm.object.modal.css');

/* @ngInject */
export default class EditAlarmObjectController {
  public $rootScope: any;
  public $uibModal: any;
  public $uibModalInstance: any;
  public $log: any;
  public dataService: any;
  public helperService: HelperService;
  public account: UserAccount;
  public mode: string = 'PLACEHOLDER';
  public isAddressExpanded = false;
  public isBMZExpanded = false;
  public isDangerExpanded = false;
  public isOverallExpanded = false;
  public alarmObject: AlarmObject;
  public alarmObjectId: string;
  public isEditable = false;
  public mapMode: any;
  public isLoading = false;
  public hasAtLeastOneEditPrivilege = false;
  public icon: any;

  constructor($rootScope: IRootScopeService, $uibModal, $uibModalInstance, $log: ILogService, dataService,
    helperService: HelperService, alarmObject: AlarmObject, alarmObjectId: string,
    public privilegeService: PrivilegeService,
    private restService: RestService,
    private $scope: IScope,
    private readonly Notification: any,
    private readonly $translate: any
  ) {
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.$log = $log;
    this.dataService = dataService;
    this.helperService = helperService;
    this.$log.debug('Opening EditAlarmObjectController...');

    this.account = this.dataService.getAccount();
    this.checkIfRoleHasAtLeastOneEditPrivilege();
    if (alarmObject) {
      this.alarmObject = alarmObject;
      this.alarmObjectId = alarmObject.id;
      //Is editable?
      this.isEditable = this.account.username === this.alarmObject.parent;
      this.calculateFirstMode();
    } else {
      this.alarmObjectId = alarmObjectId;
      //Is editable?
      this.isEditable = false;
    }
    this.$log.debug('IsEditable: ' + this.isEditable);
    this.mapMode = 'ADDRESS';

    // Load
    this.isLoading = true;
    this.dataService.getAlarmObjectById(this.alarmObjectId, (alarmObject) => {
      this.alarmObject = alarmObject;
      this.$log.info(alarmObject);
      this.isLoading = false;
      this.icon = 'icon-' + this.helperService.getIconForObjectType(this.alarmObject.type).iconName + '-small';
      this.isEditable = this.account.username === this.alarmObject.parent;
      this.calculateFirstMode();
    }, (response) => {
      //Error occured
      this.$log.error(response);
      this.isLoading = false;
    });

  }

  calculateFirstMode() {
    if (this.privilegeService.has(RolePrivilege.Objects_Database_General_Read)) {
      this.mode = 'OVERALL';
    } else {
      if (this.privilegeService.has(RolePrivilege.Objects_Database_Address_Read)) {
        this.mode = 'ADDRESS';
      } else {
        if (this.privilegeService.has(RolePrivilege.Objects_Database_BMA_Read)) {
          this.mode = 'BMZ';
        } else {
          if (this.privilegeService.has(RolePrivilege.Objects_Database_Hazards_Read)) {
            this.mode = 'DANGER';
          } else {
            if (this.privilegeService.has(RolePrivilege.Objects_Database_Radio_Read)) {
              this.mode = 'RADIO';
            } else {
              if (this.privilegeService.has(RolePrivilege.Objects_Database_Safety_Read)) {
                this.mode = 'SECURITY';
              } else {
                if (this.privilegeService.has(RolePrivilege.Objects_Database_Contacts_Read)) {
                  this.mode = 'CONTACTS';
                } else {
                  if (this.privilegeService.has(RolePrivilege.Objects_Database_Events_Read)) {
                    this.mode = 'EVENTS';
                  } else {
                    if (this.privilegeService.has(RolePrivilege.Objects_Database_Documents_Read)) {
                      this.mode = 'DOCUMENTS';
                    } else {
                      if (this.privilegeService.has(RolePrivilege.Objects_Database_Subobject_Read)) {
                        this.mode = 'BUILDINGS';
                      } else {
                        if (this.privilegeService.has(RolePrivilege.Objects_Database_Markers_Read)) {
                          this.mode = 'PATHS';
                        } else {
                          if (this.privilegeService.has(RolePrivilege.Objects_Database_Map_Read)) {
                            this.mode = 'MAP';
                          } else {
                            if (this.privilegeService.has(RolePrivilege.Objects_Database_Notes_Read)) {
                              this.mode = 'NOTES';
                            } else {
                              if (this.privilegeService.has(RolePrivilege.Objects_Database_History_Read)) {
                                this.mode = 'PROTOCOL';
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  selectMapMode(mode) {
    this.mapMode = mode;
    this.$rootScope.$emit('alarm.object.map.mode', {
      mode: mode
    });
  }

  changeMode(mode) {
    this.mode = mode;
  };

  /**
   * Expand the menu
   */
  expand(ex) {
    switch (ex) {
      case 'DANGER':
        this.isAddressExpanded = false;
        this.isBMZExpanded = false;
        this.isOverallExpanded = false;
        if (this.isDangerExpanded) {
          this.isDangerExpanded = false;
        } else {
          this.isDangerExpanded = true;
        }
        break;
      case 'BMZ':
        this.isAddressExpanded = false;
        this.isDangerExpanded = false;
        this.isOverallExpanded = false;
        if (this.isBMZExpanded) {
          this.isBMZExpanded = false;
        } else {
          this.isBMZExpanded = true;
        }
        break;
      case 'ADDRESS':
        this.isBMZExpanded = false;
        this.isDangerExpanded = false;
        this.isOverallExpanded = false;
        if (this.isAddressExpanded) {
          this.isAddressExpanded = false;
        } else {
          this.isAddressExpanded = true;
        }
        break;
      case 'OVERALL':
        this.isBMZExpanded = false;
        this.isDangerExpanded = false;
        this.isAddressExpanded = false;
        this.isOverallExpanded = !this.isOverallExpanded;
        break;
    }
  };

  cancel() {
    this.$uibModalInstance.close();
  };

  /**
   * Save object
   */
  save() {
    this.isLoading = true;
    this.dataService.updateAlarmObject(this.alarmObject, (alarmObject) => {
      this.alarmObject = alarmObject;
      this.$log.info(alarmObject);
      this.isLoading = false;
      this.$rootScope.$emit(alarmObject.id + '.nbrOfNewFeedback', alarmObject.nbrOfNewFeedback);
    }, (response) => {
      //Error occured
      this.$log.error(response);
      this.isLoading = false;
    });
  };

  selectMap() {
    this.$rootScope.$emit('map.address.update');
  }

  checkIfRoleHasAtLeastOneEditPrivilege(): void {
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Address_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_BMA_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_Buildings_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_ChangeRequests_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_Contacts_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_DirectionCards_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_DirectionCards_Delete) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_Documents_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_Events_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_General_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_Subobject_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_Safety_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_Radio_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_Notes_Write) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_Markers_Edit) ||
      this.privilegeService.has(RolePrivilege.Objects_Database_Hazards_Edit)) {
      this.hasAtLeastOneEditPrivilege = true;
    } else {
      this.hasAtLeastOneEditPrivilege = false;
    }
  }

  /**
   * Delete object
   */
  delete() {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.dataService.deleteAlarmObjectById(this.alarmObject, () => {
              this.isLoading = false;
              this.$rootScope.$emit('objects.deleted', this.alarmObject);
              this.$uibModalInstance.close();
            }, (response) => {
              //Error occured
              this.isLoading = false;
              this.$log.error(response);
            });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  };

  resetExamination() {
    this.restService.resetExaminationForAlarmObject(this.alarmObject.id)
      .then((result) => this.$translate(['EXAMINATIONS.RESET_SUCCESS']).then(translations =>
        this.Notification.success({
          title: translations['EXAMINATIONS.RESET_SUCCESS'],
          message: result.message
        })
      ))
      .catch(err => this.$log.error(err.data.message));
  }
}
